<template>
  <div class="vuse-content-wrapper">
    <v-container fluid>

    <horizontal-menu :menuItems="menuItems" />


    <v-row no-gutters>
      <v-col
        cols="12"
        sm="6"
      >
        <vue-funnel-graph :width="width" :height="height" :labels="labels"
            :values="values" :colors="colors" :sub-labels="subLabels" :direction="direction"
            :gradient-direction="gradientDirection"
            :animated="true" :display-percentage="true"
        />
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-expansion-panels>

          <v-expansion-panel>
            <v-expansion-panel-header v-slot="{ open }">
              <v-row no-gutters>
                <v-col cols="4">
                  Location
                </v-col>
                <v-col
                  cols="8"
                  class="text--secondary"
                >
                  <v-fade-transition leave-absolute>
                    <span
                      v-if="open"
                      key="0"
                    >
                      Select trip destination
                    </span>
                    <span
                      v-else
                      key="1"
                    >
                      {{ trip.location }}
                    </span>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row no-gutters>
                <v-spacer/>
                <v-col cols="5">
                  <v-select
                    v-model="trip.location"
                    :items="locations"
                    chips
                    flat
                    solo
                  />
                </v-col>

                <v-divider
                  vertical
                  class="mx-4"
                />

                <v-col cols="3">
                  Select your destination of choice
                  <br>
                  <a href="#">Learn more</a>
                </v-col>
              </v-row>

              <v-card-actions>
                <v-spacer/>
                <v-btn
                  text
                  color="secondary"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header v-slot="{ open }">
              <v-row no-gutters>
                <v-col cols="4">
                  Start and end dates
                </v-col>
                <v-col
                  cols="8"
                  class="text--secondary"
                >
                  <v-fade-transition leave-absolute>
                    <span v-if="open">When do you want to travel?</span>
                    <v-row
                      v-else
                      no-gutters
                      style="width: 100%"
                    >
                      <v-col cols="6">
                        Start date: {{ trip.start || 'Not set' }}
                      </v-col>
                      <v-col cols="6">
                        End date: {{ trip.end || 'Not set' }}
                      </v-col>
                    </v-row>
                  </v-fade-transition>
                </v-col>
              </v-row>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row
                justify="space-around"
                no-gutters
              >
                <v-col cols="3">
                  <v-menu
                    ref="startMenu"
                    :close-on-content-click="false"
                    :return-value.sync="trip.start"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="trip.start"
                        label="Start date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      scrollable
                    >
                      <v-spacer/>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.startMenu.isActive = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.startMenu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>

                <v-col cols="3">
                  <v-menu
                    ref="endMenu"
                    :close-on-content-click="false"
                    :return-value.sync="trip.end"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="trip.end"
                        label="End date"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      scrollable
                    >
                      <v-spacer/>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endMenu.isActive = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.endMenu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>



    </v-container>
  </div>
</template>
<script>

import HorizontalMenu from '@/components/App/HorizontalMenu';
import { VueFunnelGraph } from 'vue-funnel-graph-js'

export default {
  components: {
    HorizontalMenu,
    VueFunnelGraph
  },
  data: () => (
    {
      menuItems: [
        {
          id: 1,
          name: 'Crear Funnel',
          icon: 'mdi-arrow-decision',
          link: '/business/expansion/createworkflow'
        },
      ],
      date: null,
      trip: {
        name: '',
        location: null,
        start: null,
        end: null,
      },
      locations: ['Australia', 'Barbados', 'Chile', 'Denmark', 'Ecuador', 'France'],
      labels: [
        'Formulario de solicitud',
        'Analisis y Pre aprobacion',
        'Solicitud de Datos',
        'Verificacion de datos',
        'Aprobacion de datos'
        ],
          subLabels: ['Email', 'Web OLA', 'Directorio'],
          values: [
          // with the given Labels and SubLabels here's what the values represent:
          //
          // Direct, Social, Ads
          //    |      |     |
          //    v      v     v
              [9856, 5489, 9988], // Segments of "Impressions" from top to bottom
              [3000, 2500, 6500], // Segments of "Impressions" from top to bottom
              [1500, 1700, 1000], // Segments of "Add To Cart"
              [600,  200,  130],   // Segments of "Buy"
              [99,  45,  5]   // Segments of "Buy"
          ],
          colors: [
              ['#4f9ddf'], // color set for "Impressions" segment
              ['#FF3C8E'], // color set for "Impressions" segment
              ['#EC77FF'], // color set for "Add To Cart" segment
              ['#7795FF'],  // color set for "Buy" segment
              ['#7795FF']  // color set for "Buy" segment
          ],
          direction: 'vertical',
          gradientDirection: 'horizontal',
          height: 350,
          width: 500
    }
  ),
};
</script>
<style>
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__value {
    color: #193a56;
}
.svg-funnel-js .svg-funnel-js__subLabels .svg-funnel-js__subLabel {
    color: #193a56;
}
.svg-funnel-js .svg-funnel-js__labels .svg-funnel-js__label .label__percentage {
    color: #193a56;
}
</style>
